/* eslint-disable @typescript-eslint/no-inferrable-types */
import Axios from "axios";
import { BASE_URL_MANAGER, BASE_URL_ORCHESTATOR } from "@/config";
import { store } from "@/app/store";
import AdminNavbar from "@/app/components/admin-navbar/AdminNavbar.vue";
import Vue from 'vue';
import { VueWizard } from "@/vue-wizard";
import { Component } from "vue-property-decorator";

import CreateStateModal from '@/app/components/bussiness-states-management/dedicated-components/create-state-modal/CreateStateModal.vue';
import * as bootstrap from "bootstrap"
import $ from 'jquery';


import BussinesStatusAdministrator from '@/app/components/bussiness-states-management/bussines-status-administrator/BussinesStatusAdministrator.vue'
import NavBar from "@/app/components/shared/nav-bar/NavBar.vue";

@Component({
    name: "bussiness-states-management",
    components: { AdminNavbar, BussinesStatusAdministrator, CreateStateModal,NavBar }
})
export default class BussinessStatesManagement extends VueWizard {

    public EventBus: Vue = new Vue();
    public projectList: string[] = [];
    public projectListStates: any[] = []
    public loading: boolean = false;

    mounted() {
        this.retrieveInfo();
    }

    created() {
        this.EventBus.$on('reloadList', () => {
            this.retrieveInfo();
        });
    }

    async retrieveInfo() {
        this.loading = true;
        await this.getProjects();
        await this.getProjectStates();
        this.loading = false;
    }

    async getProjectStates() {
        try {
            this.projectListStates = [];

            this.asyncForEach(this.projectList, async (project: any) => {

                let url = `${BASE_URL_MANAGER}/project/${project._id}`;

                const res = await Axios.get(url);

                if (res.data != null) {

                    const { bussinesStatusList, name, _id } = res.data;

                    this.projectListStates.push({
                        _id: _id,
                        name: name,
                        bussinesStatusList: bussinesStatusList
                    });
                }

            });

        } catch (err) {

            throw err;
        }
    }

    async getProjects() {
        try {
            let url = `${BASE_URL_MANAGER}/company/${store.state.user!.user.company._id}`;

            let res = await Axios.get(url);

            this.projectList = res.data.projects;

        } catch (err) {
            throw err;
        }
    }

    async asyncForEach(array: any, callback: any) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }

    enable(id: string) {
        $('#' + id).modal('show');
    }

    openModal(id: string) {
        this.EventBus.$emit('cleanModal');
        this.enable(id)
    }

}