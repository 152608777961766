import {VueWizard} from "@/vue-wizard";
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Axios from "axios";
import {BASE_URL_MANAGER} from "@/config";
import Swal from "sweetalert2"
import { timers } from 'jquery';
const _ = require('lodash');



@Component({name:'bussines-status-card'})
export default class BussinesCard extends VueWizard{
    @Prop() readonly status!: any;
    @Prop() readonly idProject!: String;
    @Prop() readonly EventBus!: Vue;

    public name: String ="";
    public description: String ="";
    public message: String ="";
    public  statusToEdit:any = {};
    public  statusEdited:any = {};
    public selectedColor: String = '';
    public nameOfBussinesStatusToEdit: any = ""; 

    showModal(statusEdit: any){
        
        this.statusToEdit = _.cloneDeep(statusEdit);
        this.statusEdited = _.cloneDeep(statusEdit);
        this.statusToEdit.nameOfBussinessStatusToEdit = statusEdit.name; 
        this.selectedColor = statusEdit.color;

        var modal = document.getElementById(this.status._id+"modal");
        if(modal){
            var body = document.getElementById(this.status._id+"card")
            var modalFade =document.createElement("div");
            if(body){
                body.append(modalFade);
                modalFade.id = this.status._id+"modal_fade"
                modalFade.className = "modal-backdrop fade show";
            }     
            modal.className = "modal fade show";
            modal.style.cssText = "display: block; padding-right: 17px; overflow: scroll";
        }
    }
    quitModal(){
        var modal = document.getElementById(this.status._id+"modal");
        if(modal){
            var body = document.getElementById(this.status._id+"card")
            modal.className = "modal fade";
            modal.style.cssText = "display: none";
            if (body) {
                var modalFade = document.getElementById(this.status._id+"modal_fade")
                if (modalFade) {
                    body.removeChild(modalFade);   
                }
            };
        }
        this.statusToEdit = {}
    }

    handleNameInput(event: any){
        event.target.value = event.target.value.replace(/[^A-Za-z0-9_]/g,'');
        event.target.value = event.target.value.toUpperCase();
        this.statusToEdit.name = event.target.value;
    }

    handleDescriptionInput(e: any) {
        this.description = e;
    }

    handleMessageInput(e: any){
        this.message = e;
    }

    deleteStatus(nameToDelete: any){
        Swal.fire({
            title: 'Eliminar estado',
            icon: 'info',
            html: '¿Desea eliminar el estado?',
            showCloseButton: true,
            showCancelButton: true,
            cancelButtonText:"Cancelar",
            focusConfirm: false,
            confirmButtonText:
                'Aceptar',
        }).then(async (result) => {
            if (result.value) {
                const opt = {
                    name: nameToDelete.name
                }
                await Axios.delete(`${BASE_URL_MANAGER}/project/${this.status._id}/bussinesstatus`,{
                    data: opt
                }).then((res: any) =>{
                    if (res.data.deleted) {
                        this.removeItemFromArr(this.status.bussinesStatusList, nameToDelete)
                        Swal.fire({
                            title: 'Eliminar estado',
                            icon: 'info',
                            html: 'Se ha eliminado correctamente',
                            showCloseButton: true,
                            showCancelButton: false,
                            focusConfirm: false,
                        })
                    }
                })
            }
        });
    }

    removeItemFromArr ( arr: any, item:any ) {
        var i = arr.indexOf( item );
        if ( i !== -1 ) {
            arr.splice( i, 1 );
        }
    }

    public update(){
        this.statusToEdit.color = this.selectedColor; 
        for (let index = 0; index < this.status.bussinesStatusList.length; index++) {
            if (this.statusToEdit.name === this.status.bussinesStatusList[index].name && 
                this.statusToEdit.nameOfBussinessStatusToEdit != this.status.bussinesStatusList[index].name) {
                    Swal.fire({
                        title: 'Hubo un Error',
                        icon: 'error',
                        html: 'Ya existe otro estado con el mismo nombre',
                        showCloseButton: true,
                        showCancelButton: false,
                        focusConfirm: false,
                    })
                    return;
            }
        }
        Axios.put(`${BASE_URL_MANAGER}/project/bussinesStatus/edit/${this.status._id}`, this.statusToEdit)
        .then((res: any) =>{
                if (res.data.status != "400") {
                    Swal.fire({
                        title: 'Edición Exitosa',
                        icon: 'info',
                        html: 'Se ha editado el estado correctamente',
                        showCloseButton: true,
                        showCancelButton: false,
                        focusConfirm: false,
                    })
                    this.quitModal();
                }
                this.EventBus.$emit("reloadList")
            
        }).catch((err: any)=>{
            console.log(err);
            
            Swal.fire({
                title: 'Hubo un Error',
                icon: 'error',
                html: 'Ha ocurrido un error al editar el estado',
                showCloseButton: true,
                showCancelButton: false,
                focusConfirm: false,
            })
            this.quitModal()
        })
    }

    public colorList: string[] = [
        '#8EA9DB',
        '#B4C6E7',
        '#D9E1F2',

        '#F4B084',
        '#F8CBAD',
        '#FCE4D6',

        '#FFD966',
        '#FFE699',
        '#FFF2CC',

        '#A9D08E',
        '#C6E0B4',
        '#E2EFDA',
    ];
    

    changeSelectedColor(color: string){
        this.selectedColor = color;
    }

    
}