import {VueWizard} from "@/vue-wizard";
import {Component, Vue} from "vue-property-decorator";
import { Prop } from 'vue-property-decorator';

import BussinesCardDeck from '@/app/components/bussiness-states-management/dedicated-components/bussines-card-deck/BussinesCardDeck.vue';

@Component({
    name: "bussines-status-administrator",
    components: { BussinesCardDeck }
})
export default class BussinesAdministrator extends VueWizard {
    @Prop() readonly EventBus!: Vue;
    @Prop() readonly bussinesList!: any;

}
